const icon = {
  logo: require("./logo.svg").default,
  search: require("./search.svg").default,
  heart: require("./heart.svg").default,
  heartActive: require("./heartActive.svg").default,
  heartNonActive: require("./heartNonActive.svg").default,
  cart: require("./card.svg").default,
  arrowDown: require("./ArrowDown.svg").default,
  polygon: require("./Polygon.svg").default,
  iconTv: require("./IconTv.svg").default,
  wearable: require("./Wearable.svg").default,
  computers: require("./Computers.svg").default,
  savings: require("./Savinngs.svg").default,
  cellPhone: require("./Cellphones.svg").default,
  allDepartments: require("./AllDepartments.svg").default,
  background: require("./background.svg").default,
  vectorBackground: require("./Vectorbackground.svg").default,
  bag: require("./Bag.svg").default,
  buy: require("./Buy.svg").default,
  document: require("./Document.svg").default,
  star: require("./Star.svg").default,
  bakeryBread: require("./BakeryBread.svg").default,
  beverages: require("./Beverages.svg").default,
  cocktailsMixesCoffee: require("./CocktailsMixesCoffee.svg").default,
  freshProduce: require("./Fresh Produce.svg").default,
  frozen: require("./Frozen.svg").default,
  grocery: require("./Grocery.svg").default,
  snacks: require("./Snacks.svg").default,
  filter: require("./Filter.svg").default,
  danger: require("./Danger Circle.svg").default,
  location: require("./Location.svg").default,
  arrowRight: require("./ArrowRight.svg").default,
  arrowLeft: require("./ArrowLeft.svg").default,
  delete: require("./Delete.svg").default,
  trendingInBeauty: require("./Trending in Beauty.svg").default,
  featuredBrands: require("./Featured Brands.svg").default,
  communitiesToSupport: require("./Communities to Support.svg").default,
  premiumBeauty: require("./Premium Beauty.svg").default,
  beauty: require("./Beauty.svg").default,
  candy: require("./Candy.svg").default,
  download: require("./Downlaod.svg").default,
  profile: require("./Profile.svg").default,
  shieddone: require("./Shield Done.svg").default,
  giflt: require("./gift.svg").default,
  message: require("./Message.svg").default,
  jakirHuyssen: require("./JakirHussen.svg").default,
  jubedAhmed: require("./JubedAhmed.svg").default,
  delwarHussain: require("./DelwarHussain.svg").default,
  startOfPeople: require("./star-people.svg").default,
  giftAddToCart: require("./giftAddToCart 37.svg").default,
  calling: require("./Calling.svg").default,
  back: require("./back.svg").default,
  btnAdd: require("./add.svg").default,
  btnReduce: require("./reduce.svg").default,
  edit: require("./Edit.svg").default,
  tick: require("./Tick.svg").default,
  tickActive: require("./TickActive.svg").default,
  google: require("./google.svg").default,
  check: require("./check.svg").default,
  noCheck: require("./noCheck.svg").default,
  emailIcon: require("./emailIcon.svg").default,
  username: require("./username.svg").default,
  lock: require("./Lock.svg").default,
  unlock: require("./unlock.svg").default,
  smartphone: require("./smartphone.svg").default,
  fullName: require("./fullName.svg").default,
  ok: require("./ok.svg").default,
  camera: require("./camera.svg").default,
  loading: require("./loading.svg").default,
};

export default icon;
